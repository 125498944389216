// @ts-ignore
import { querySelectorAll } from 'common/js/utils/dom';
// @ts-ignore
import { showModal } from 'common/js/modal/modal';
// @ts-ignore
import { getEventFromDatasetAtIndex } from 'common/js/parts/tracking';
import CountdownTimer from './classes/CountdownTimer';

const domScriptTemplatePopup = querySelectorAll('script[data-js-popup="leave"]');

const convertToSlug = (name: string) => name.toLowerCase().replace(/([^\w ]|\s)+/g, '');

if (domScriptTemplatePopup.length) {
    const affLinks = querySelectorAll('[data-js-clickout]');
    let countdownTimer: CountdownTimer | null = null;

    const leaveBehindTargetProductsSet: string[] = [];

    domScriptTemplatePopup.forEach((popup) => {
        let targetProducts = popup.getAttribute('data-js-target-products')?.split(',');

        targetProducts = targetProducts
            // eslint-disable-next-line max-len
            ? targetProducts.map(convertToSlug).filter((v) => !leaveBehindTargetProductsSet.includes(v))
            : [];

        if (targetProducts.length) {
            affLinks.forEach((affLink) => {
                const productName = getEventFromDatasetAtIndex(affLink.dataset, 'el') || affLink.getAttribute('title') || '';
                // convert full product name to product slug because full product name is
                // stored in afflink but list of product slug comes from elastic search
                const affLinkProductSlug = convertToSlug(productName);

                if (targetProducts?.includes(affLinkProductSlug)) {
                    affLink.addEventListener('click', (e) => {
                        if (e.button !== 1) {
                            showModal(
                                popup,
                                (el) => {
                                    // @ts-ignore
                                    const domCountdownTimerElement = el.querySelector<HTMLElement>('[data-js-countdown]');

                                    if (domCountdownTimerElement) {
                                        // eslint-disable-next-line max-len
                                        countdownTimer = new CountdownTimer(domCountdownTimerElement);
                                    }
                                },
                                () => {
                                    if (countdownTimer) {
                                        countdownTimer!.stopTimer();
                                    }
                                },
                            );
                        }
                    });
                }
            });
            leaveBehindTargetProductsSet.push(...targetProducts);
        }
    });
}

export {};
