import MicroModal from '@micromodal/repo/lib/src';
import { clickOutElementSelector, pushToDl, onAllClickEvents } from '../parts/tracking';
import { insertTemplate } from '../utils/dom';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import appWindowObject from '../window';

export const popupSelector = '[data-js-popup]';

function sendToDl(popupDomId: string, eventAction: string) {
    const popupModalData = document.querySelector<HTMLDivElement>(`#${popupDomId}`)?.dataset;

    if (popupModalData?.ec) {
        pushToDl({
            event: popupModalData.e || '',
            eventCategory: popupModalData.ec,
            eventAction: eventAction || popupModalData.ea || '',
            eventLabel: popupModalData.el || '',
        });
    }
}

// eslint-disable-next-line import/prefer-default-export
export function showModal(
    domScriptTemplate?: HTMLElement,
    onShow?: (el: HTMLDivElement) => void,
    onHide?: () => void,
): void {
    if (window.appWindowObject.ui.currentOpenedModalId) {
        return;
    }

    if (domScriptTemplate) {
        const popupData = domScriptTemplate.dataset;
        const id = popupData?.jsPopupId || '';

        insertTemplate(domScriptTemplate, document.body, false);

        const domPopupModal = document.querySelector<HTMLDivElement>(`#${id}`);

        if (domPopupModal) {
            const customCloseButton = domPopupModal.querySelectorAll('[data-micromodal-close-with-delay]');

            if (customCloseButton.length > 0) {
                customCloseButton.forEach((button) => {
                    const delay = parseInt(button.getAttribute('data-micromodal-close-with-delay') ?? '', 10);

                    button.addEventListener('click', () => {
                        setTimeout(() => {
                            MicroModal.close(id);
                        }, delay ?? 1500);
                    });
                });
            }

            MicroModal.show(id, {
                onShow: () => {
                    window.appWindowObject.ui.currentOpenedModalId = id;
                    window.appWindowObject.events.emit('ui:modal-visibility', id, true);

                    sendToDl(id, 'load');

                    if (onShow) {
                        onShow(domPopupModal);
                    }
                },
                onClose: () => {
                    window.appWindowObject.ui.currentOpenedModalId = null;
                    window.appWindowObject.events.emit('ui:modal-visibility', id, false);

                    sendToDl(id, 'close');

                    if (onHide) {
                        onHide();
                    }
                },
                disableScroll: true,
                disableFocus: true,
            });
        }
    }
}

export function handleClickoutEventInPopup(eventTarget: HTMLElement) {
    const domPopupModal = eventTarget.closest<HTMLDivElement>(popupSelector);

    if (domPopupModal) {
        sendToDl(domPopupModal.id, 'clicked');
    }
}

export function init() {
    onAllClickEvents(`${popupSelector} ${clickOutElementSelector}`, handleClickoutEventInPopup);
}

export default {
    init,
    showModal,
};
