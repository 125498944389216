export default class CountdownTimer {
    private readonly domElements: {
        hours: HTMLElement | null,
        minutes: HTMLElement | null,
        seconds: HTMLElement | null,
        days: HTMLElement | null,
    } = {
            hours: null,
            minutes: null,
            seconds: null,
            days: null,
        };

    private seconds = 0;

    interval: number | undefined = undefined;

    tickHandler = () => {
        const seconds = Math.floor((this.seconds) % 60);
        const minutes = Math.floor((this.seconds / 60) % 60);
        const hours = Math.floor((this.seconds / (60 * 60)) % 24);
        const days = Math.floor((this.seconds / (60 * 60 * 24)));

        this.seconds -= 1;

        if (this.seconds < 0) {
            this.stopTimer();
        }

        this.domElements.seconds!.innerText = CountdownTimer.formatTimeItem(seconds);
        this.domElements.minutes!.innerText = CountdownTimer.formatTimeItem(minutes);
        this.domElements.hours!.innerText = CountdownTimer.formatTimeItem(hours);
        this.domElements.days!.innerText = CountdownTimer.formatTimeItem(days);
    };

    stopTimer() {
        window.clearInterval(this.interval);
    }

    private static formatTimeItem = (value: number) => (value < 10 ? `0${value}` : value).toString();

    constructor(el: HTMLElement) {
        const searchItem = (name) => el.querySelector<HTMLElement>(`[data-js-countdown="${name}"]`);

        this.domElements = {
            hours: searchItem('h'),
            minutes: searchItem('m'),
            seconds: searchItem('s'),
            days: searchItem('d'),
        };

        this.seconds = parseInt(el.dataset.jsCountdownDuration || '', 10) || 0;

        if (this.seconds) {
            this.tickHandler();
            this.interval = window.setInterval(this.tickHandler, 1000);
        }
    }
}
